















































































































import { computed, defineComponent, nextTick, PropType, ref, watch } from '@vue/composition-api';
import { ExclamationCircleIcon, PlusIcon } from '@vue-hero-icons/solid';
import { TriggerCondition, Workflow, WorkflowTrigger } from '../../types';
import { TriggerDataLoadedCondition, TriggerPipelineExecutedCondition, TriggerSummary } from '.';
import { ConfirmButton, Scrollbar } from '@/app/components';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { clone, invertObj, isNil } from 'ramda';
import {
    TriggerDataType,
    TriggerEntityType,
    TriggerPipelineStatus,
    TriggerEntityConditionType,
    TriggerConditionType,
    TRIGGER_MAP,
    TRIGGER_OPERATORS_MAP,
    TRIGGER_PIPELINE_STATUS_MAP,
    TTriggerType,
} from '../../constants';
import { CreateTriggerDTO, UpdateTriggerDTO } from '../../dto/trigger.dto';
import { XCircleIcon } from '@vue-hero-icons/solid';
import { Asset } from '@/modules/asset/types';
import { DataCheckinJob } from '@/modules/data-checkin/types';

export default defineComponent({
    name: 'TriggerEditor',
    components: {
        ValidationProvider,
        TriggerDataLoadedCondition,
        TriggerPipelineExecutedCondition,
        ValidationObserver,
        PlusIcon,
        Scrollbar,
        XCircleIcon,
        ExclamationCircleIcon,
        ConfirmButton,
        TriggerSummary,
    },
    model: { prop: 'trigger', event: 'change' },
    props: {
        workflow: {
            type: Object as PropType<Workflow>,
            required: true,
        },
        trigger: {
            type: Object as PropType<WorkflowTrigger>,
        },
        saving: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }: { emit: any; root: any }) {
        const conditions = ref<TriggerCondition[]>([]);
        const triggerRef = ref<any>(null);
        const editMode = ref<boolean>(false);
        const initialConditions = ref<string | null>(null);
        const summary = ref<string[]>([]);

        const assets = ref<Asset[]>([]);
        const dataCheckinPipelines = ref<DataCheckinJob[]>([]);
        const analyticsPipelines = ref<Workflow[]>([]);

        const conditionTypes = [
            {
                id: TriggerConditionType.DataLoaded,
                name: 'When new data arrive',
            },
            {
                id: TriggerConditionType.PipelineExecuted,
                name: 'When another pipeline has been executed',
            },
        ];

        const addCondition = () =>
            conditions.value.push({
                type: null,
                entityId: null,
                entityName: null,
                entityType: null,
                entityTypeId: null,
                checks: {},
            } as TriggerCondition);

        const cancelTrigger = () => emit('cancel', hasChanges.value);

        const saveTrigger = async () => {
            if (!triggerRef.value) return;

            const valid = await triggerRef.value.validate();

            if (valid) {
                const data = clone(conditions.value);

                if (props.trigger && editMode.value) {
                    const updatePayload: UpdateTriggerDTO = {
                        id: props.trigger.id,
                        conditions: data.map((condition: TriggerCondition) => mapConditionForServer(condition)),
                        configuration: {
                            summary: summary.value,
                        },
                    };
                    emit('save', updatePayload);
                } else {
                    const createPayload: CreateTriggerDTO = {
                        workflowId: props.workflow.id,
                        isEnabled: !props.disabled,
                        conditions: data.map((condition: TriggerCondition) => mapConditionForServer(condition)),
                        configuration: {
                            summary: summary.value,
                        },
                    };
                    emit('save', createPayload);
                }
            }
        };

        const mapConditionForServer = (condition: TriggerCondition) => {
            if (condition.entityTypeId) {
                condition.type = condition.entityTypeId as TTriggerType;
                delete condition.entityTypeId;
            }

            if (condition.checks.status) {
                condition.checks.status = TRIGGER_PIPELINE_STATUS_MAP[condition.checks.status];
            }

            mapConditionChecksForServer(condition.checks.filters);
            return condition;
        };

        const mapConditionChecksForServer = (checks: any[]) => {
            checks?.map(
                (c: any) =>
                    (c.type = [TriggerDataType.Double, TriggerDataType.Integer].includes(c.type) ? 'number' : c.type),
            );
            return checks;
        };

        const handleConditionChange = (condition: TriggerCondition) => {
            condition.entityId = null;
            condition.entityName = null;
            condition.entityType = null;
            condition.entityTypeId = null;
            condition.checks = {};
        };

        const updateTrigger = (trigger: any) => {
            const clonedTrigger = clone(trigger);
            if (clonedTrigger) {
                conditions.value = clonedTrigger.conditions.map((condition: any) => ({
                    ...condition,
                    entityTypeId: condition.type,
                    type: TRIGGER_MAP[condition.type],
                    checks: {
                        ...condition.checks,
                        ...(condition.checks.status
                            ? { status: invertObj(TRIGGER_PIPELINE_STATUS_MAP)[condition.checks.status] }
                            : {}),
                    },
                }));
                initialConditions.value = JSON.stringify(conditions.value);
                editMode.value = true;
            } else {
                conditions.value = [];

                addCondition();
                editMode.value = false;
            }
        };

        const saveButtonLabel: any = computed(() =>
            editMode.value ? `Updat${props.saving ? 'ing' : 'e'}` : `Sav${props.saving ? 'ing' : 'e'}`,
        );

        const removeCondition = (_: TriggerCondition, index: number) => conditions.value.splice(index, 1);

        const hasChanges = computed(() => {
            const clonedConditions = clone(conditions.value).map((condition: TriggerCondition) => {
                mapConditionChecksForServer(condition.checks.filters);
                return condition;
            });

            return initialConditions.value && initialConditions.value !== JSON.stringify(clonedConditions);
        });

        const getHasChanges = () => hasChanges.value;

        const getSummary = () => {
            const clonedConditions = clone(conditions.value);
            if (!clonedConditions.length) return [];

            let triggerSummary: any[] = [];

            clonedConditions.forEach((condition: TriggerCondition) => {
                const conditionType = conditionTypes.find(
                    (type: { id: string; name: string }) => type.id === condition.type,
                );

                if (conditionType) {
                    if (conditionType.id === TriggerConditionType.DataLoaded && condition.entityTypeId) {
                        const conditionSummary: {
                            condition: string;
                            checks?: string[];
                            evaluation?: string;
                        } = {
                            condition: '',
                        };

                        if (!condition.entityName) {
                            condition.entityName =
                                assets.value?.find((asset: Asset) => asset.id === Number(condition.entityId))?.name ||
                                '';
                        }

                        const entityType =
                            condition.entityTypeId === TriggerEntityConditionType.Dataset ? 'dataset' : 'result';

                        conditionSummary.condition = `when new data arrive in input ${entityType} "${condition.entityName}"`;
                        if (condition.checks?.filters?.length) {
                            conditionSummary.checks = [];

                            condition.checks.filters.forEach((check: any) => {
                                if (check.field && check.operator && !isNil(check.value)) {
                                    conditionSummary.checks?.push(
                                        `the "${check.field}" field is ${TRIGGER_OPERATORS_MAP[
                                            check.operator
                                        ].toLowerCase()} "${check.value}"`,
                                    );
                                }
                            });
                        }

                        if (condition.checks?.evaluation) {
                            conditionSummary.evaluation = `evaluated on the ${condition.checks.evaluation.toLowerCase()} row`;
                        }

                        triggerSummary.push(conditionSummary);
                    }
                    if (
                        conditionType.id === TriggerConditionType.PipelineExecuted &&
                        condition.entityType &&
                        condition.checks?.status
                    ) {
                        const conditionSummary: { condition: string; status: string } = {
                            condition: '',
                            status: '',
                        };

                        if (!condition.entityName) {
                            if (condition.entityType === TriggerEntityType.DataCheckinJob)
                                condition.entityName =
                                    dataCheckinPipelines.value?.find(
                                        (pipeline: DataCheckinJob) => pipeline.workflowId === condition.entityId,
                                    )?.name || '';
                            else if (condition.entityType === TriggerEntityType.Workflow)
                                condition.entityName =
                                    analyticsPipelines?.value?.find(
                                        (pipeline: Workflow) => pipeline.id === condition.entityId,
                                    )?.name || '';
                        }

                        conditionSummary.condition =
                            condition.entityType === TriggerEntityType.DataCheckinJob
                                ? 'when data check-in pipeline'
                                : 'when data analytics pipeline';

                        conditionSummary.condition = `${conditionSummary.condition} "${condition.entityName}"`;

                        if (condition.checks.status === TriggerPipelineStatus.Success) {
                            conditionSummary.status = 'was successfully executed';
                        } else if (condition.checks.status === TriggerPipelineStatus.Failure) {
                            conditionSummary.status = 'was executed with failure';
                        }

                        triggerSummary.push(conditionSummary);
                    }
                }
            });

            return triggerSummary;
        };

        const otherPipelineIds = (idx: number) => {
            return conditions.value
                .filter((cond, i) => i !== idx && cond.type === TriggerConditionType.PipelineExecuted)
                .map((cond) => cond.entityId);
        };

        const setDataCheckinPipelines = (pipeline: DataCheckinJob) =>
            (dataCheckinPipelines.value = [...dataCheckinPipelines.value, pipeline]);

        const setAnalyticsPipelines = (pipeline: Workflow) =>
            (analyticsPipelines.value = [...analyticsPipelines.value, pipeline]);

        watch(
            () => [props.trigger, triggerRef.value],
            ([trigger, observer]: any) => {
                updateTrigger(trigger);
                if (observer) {
                    nextTick(() => {
                        if (trigger) observer.validate();
                        else observer.reset();
                    });
                }
            },
            { immediate: true },
        );

        watch(
            () => [conditions.value, assets.value, dataCheckinPipelines.value, analyticsPipelines.value],
            () => {
                summary.value = getSummary();
            },
            { deep: true },
        );

        return {
            assets,
            TriggerConditionType,
            conditionTypes,
            triggerRef,
            conditions,
            addCondition,
            saveTrigger,
            cancelTrigger,
            editMode,
            handleConditionChange,
            saveButtonLabel,
            removeCondition,
            hasChanges,
            getHasChanges,
            summary,
            dataCheckinPipelines,
            analyticsPipelines,
            initialConditions,
            otherPipelineIds,
            setDataCheckinPipelines,
            setAnalyticsPipelines,
        };
    },
});
