



































































































































































































































































































































































































































































































































































































































































































































































import { ConfirmButton, ConfirmModal, Scrollbar } from '@/app/components';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { extend, ValidationObserver } from 'vee-validate';

import Toggle from '@/app/components/Toggle.vue';
import {
    ApexChartColors,
    ApexChartLegendPosition,
    ApexChartType,
    ChartConfigType,
    VisualisationOptionsDescription,
    markerSizeOptions,
} from '@/modules/workflow-designer/constants';
import { Chart } from '@/modules/workflow-designer/types';
import ChartConfigurationInput from './ChartConfigurationInput.vue';
import ChartConfigurationPieFamilySeries from './ChartConfigurationPieFamilySeries.vue';
import ChartConfigurationSeries from './ChartConfigurationSeries.vue';
import ChartConfigurationXAxisSeriesInput from './ChartConfigurationXAxisSeriesInput.vue';
import Filters from './filters/Filters.vue';
import ValidationStatus from './ValidationStatus.vue';
import { minValueValidator } from '@/app/validators';

extend('min_value', minValueValidator);

export default defineComponent({
    name: 'ChartConfiguration',
    props: {
        availableSeries: {
            type: Array,
            default: () => [],
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        chartConfig: {
            type: Object,
            required: true,
        },
    },
    components: {
        ConfirmButton,
        ValidationObserver,
        Scrollbar,
        ConfirmModal,
        ChartConfigurationInput,
        ChartConfigurationSeries,
        ChartConfigurationPieFamilySeries,
        ChartConfigurationXAxisSeriesInput,
        Filters,
        ValidationStatus,
        Toggle,
    },
    setup(props, { emit }) {
        const showChartEditCancelConfirmation = ref<boolean>(false);
        const selectedConfigType = ref<string>(ChartConfigType.General);
        const validationRef = ref<any>(null);
        const originalConfiguration = ref<any>();
        const originalSeriesOptions = ref<any>();

        // Update event creates a copy (clone) with ChartConfig class at parent element.
        // Need both configuration and seriesOptions as emit values in order not to lose settings while creating new configuration instance
        const configuration = computed({
            get: () => props.chartConfig.configuration,
            set: (updatedConfiguration: any) => {
                emit('update', updatedConfiguration, props.chartConfig.seriesOptions);
            },
        });

        const seriesOptions = computed({
            get: () => props.chartConfig.seriesOptions,
            set: (updatedSeriesOptions) => {
                emit('update', props.chartConfig.configuration, updatedSeriesOptions);
            },
        });

        const chart = computed((): Chart => props.chartConfig.chart);

        const isPieFamily = computed(() => {
            return chart.value && chart.value.iBelongInThePieFamily();
        });

        const chartConfigType = computed(() => {
            return chart.value ? chart.value.getConfigurationSections() : [];
        });

        const yAxisSeriesOptions = computed(() => {
            const structure: any = props.availableSeries?.length ? props.availableSeries[0] : null;
            if (!structure || !chart.value) return [];

            return chart.value.yAxisSeriesOptions(structure);
        });

        const xAxisSeriesOptions = computed(() => {
            const structure: any = props.availableSeries?.length ? props.availableSeries[0] : null;
            if (!structure || !chart.value) return [];
            const xAxisSeriesType = configuration.value.xaxis.type;

            // Once user selects series type for x axis,filter series options
            return chart.value.xAxisSeriesOptions(structure, xAxisSeriesType);
        });

        const xAxisValidTypes = computed(() => {
            return props.chartConfig.chart.validXaxisTypes();
        });

        const canDelete = computed(() => {
            return props.chartConfig.isSaved();
        });

        const canSave = computed(() => {
            return (
                !props.readOnly &&
                seriesOptions?.value &&
                ((seriesOptions.value.series.length > 0 && seriesOptions.value.groupBy?.fields.length > 0) ||
                    (chart.value && chart.value.getChartType() === ApexChartType.RadialBar)) // Radialbar needs only filters
            );
        });

        const legendPosition = computed({
            get: () => configuration.value.legend.position,
            set: (updatedLegendPosition: any) => {
                updateConfiguration(['legend', 'position'], updatedLegendPosition);
            },
        });

        const xAxisType = computed({
            get: () => configuration.value.xaxis.type,
            set: (updatedXAxisType: any) => {
                handleChangeSeriesType(updatedXAxisType);
            },
        });

        const save = () => {
            emit('save');
        };

        const cancel = (confirm = true) => {
            if (confirm && !props.readOnly) {
                showChartEditCancelConfirmation.value = true;
            } else {
                emit('reset');
                showChartEditCancelConfirmation.value = false;
            }
        };

        const handleValidate = () => {
            if (validationRef?.value) {
                validationRef.value.validate();
            }
        };

        const updateConfiguration = (fieldPath: string[], value: any) => {
            const updatedConfiguration = { ...configuration.value };
            configuration.value = R.assocPath(fieldPath, value, updatedConfiguration);
        };

        const updateSeriesOptions = (fieldPath: string[], value: any) => {
            const updatedSeriesOptions = { ...seriesOptions.value };
            seriesOptions.value = R.assocPath(fieldPath, value, updatedSeriesOptions);
        };

        const handleChangeSeriesType = async (newType: string): Promise<void> => {
            await updateConfiguration(['xaxis', 'type'], newType);
            seriesOptions.value = {
                ...seriesOptions.value,
                groupBy: {
                    ...seriesOptions.value.groupBy,
                    fields: [],
                    buckets: null,
                    type: '',
                    name: '',
                },
            };
        };

        const handleHeatmapColors = (showMultiple: boolean) => {
            if (showMultiple) {
                updateConfiguration(['colors'], Chart.getPalette());
            } else {
                updateConfiguration(['colors'], [ApexChartColors.HeatmapDefault]);
            }
        };

        const disableMarkersAndLabels = computed(() => {
            const totalNoOfResults = seriesOptions.value.series.length
                ? seriesOptions.value.series.length * seriesOptions.value.pageSize
                : seriesOptions.value.pageSize;
            if (totalNoOfResults > 5000 && chart.value.getChartType() !== ApexChartType.Scatter) {
                // threshold of number of data point results to disable markers and labels
                return true;
            }
            return false;
        });

        const hasChanges = computed(
            () =>
                !R.equals(originalConfiguration.value, configuration.value) ||
                !R.equals(originalSeriesOptions.value, seriesOptions.value),
        );

        watch(
            () => disableMarkersAndLabels.value,
            (resetMarkersAndLabels: boolean) => {
                if (resetMarkersAndLabels) {
                    if (configuration.value.markers) configuration.value.markers.size = 0;
                    if (configuration.value.dataLabels) configuration.value.dataLabels.enabled = false;
                }
                emit('markers-and-labels-reset', resetMarkersAndLabels);
            },
            { immediate: true },
        );
        watch(() => [seriesOptions.value, validationRef.value], handleValidate, { immediate: true });

        watch(
            () => configuration.value,
            () => {
                if (R.isNil(originalConfiguration.value)) originalConfiguration.value = R.clone(configuration.value);
            },
            { immediate: true },
        );

        watch(
            () => seriesOptions.value,
            () => {
                if (R.isNil(originalSeriesOptions.value)) originalSeriesOptions.value = R.clone(seriesOptions.value);
            },
            { immediate: true },
        );

        return {
            save,
            cancel,
            canDelete,
            showChartEditCancelConfirmation,
            configuration,
            seriesOptions,
            yAxisSeriesOptions,
            chartConfigType,
            ChartConfigType,
            selectedConfigType,
            xAxisSeriesOptions,
            xAxisValidTypes,
            isPieFamily,
            ApexChartType,
            ApexChartLegendPosition,
            canSave,
            validationRef,
            chart,
            uuidv4,
            handleValidate,
            handleChangeSeriesType,
            updateConfiguration,
            updateSeriesOptions,
            legendPosition,
            xAxisType,
            VisualisationOptionsDescription,
            handleHeatmapColors,
            markerSizeOptions,
            disableMarkersAndLabels,
            hasChanges,
        };
    },
});
