




















































































































































































































































































































import { AccessLevelDynamicBadge, CopyToClipboardButton, ConfirmButton } from '@/app/components';
import { useAxios, useFeatureFlags, useFilters } from '@/app/composable';
import store from '@/app/store';
import { WorkflowStatus } from '@/modules/apollo/constants';
import { AssetTypeId } from '@/modules/asset/constants';
import {
    ChipIcon,
    ClockIcon,
    CogIcon,
    CubeTransparentIcon,
    DatabaseIcon,
    DuplicateIcon,
    PencilAltIcon,
    PlayIcon,
    TrashIcon,
    BellIcon,
    MinusIcon,
    XCircleIcon,
} from '@vue-hero-icons/outline';
import { CalendarIcon, ChartBarIcon, UserIcon, ExclamationCircleIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import * as R from 'ramda';
import { WorkflowAPI } from '../api';
import { ExecutionFrameworkWrapper, ExecutionStatus } from '../constants';

dayjs.extend(weekOfYear);
dayjs.extend(utc);

export default defineComponent({
    name: 'WorkflowOverview',
    props: {
        workflow: {
            type: Object,
            required: true,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
        alert: {
            type: Object as PropType<{ entityId: string | number; alertId: string; enabled: boolean }>,
            required: false,
        },
    },
    components: {
        ChartBarIcon,
        DuplicateIcon,
        PencilAltIcon,
        ClockIcon,
        TrashIcon,
        AccessLevelDynamicBadge,
        UserIcon,
        CalendarIcon,
        CogIcon,
        ChipIcon,
        CubeTransparentIcon,
        DatabaseIcon,
        CopyToClipboardButton,
        PlayIcon,
        BellIcon,
        MinusIcon,
        XCircleIcon,
        ConfirmButton,
        ExclamationCircleIcon,
    },
    setup(props: any, { emit, root }: { emit: any; root: any }) {
        const provenanceTypeStatusClass = {
            Input: {
                colour: 'bg-blue-100 text-blue-600',
                label: 'Input',
            },
            Output: {
                colour: 'bg-green-100 text-green-600',
                label: 'Output',
            },
            Both: {
                colour: 'bg-purple-100 text-purple-600',
                label: 'Input and Output',
            },
        };

        const { formatDate, formatDateTime } = useFilters();
        const { flag } = useFeatureFlags();
        const isAlertsEnabled = flag('alerts');
        const isExpanded = ref<boolean>(false);
        const { reverse } = R;
        const disabledCanceling = ref<boolean>(false);
        const { exec } = useAxios(true);

        const hasVisualisations = computed(() => props.workflow.visualisations.length > 0);

        const toggleExpand = () => {
            if (hasVisualisations.value) {
                isExpanded.value = !isExpanded.value;
            }
        };

        const cancelExecution = (workflowId: string) => {
            exec(WorkflowAPI.cancelActiveExecutions(workflowId))
                .then(() => {
                    (root as any).$toastr.s(
                        `Active executions are cancelling. Please note that this operation may need some time to finish`,
                        'Success',
                    );
                })
                .catch(() => {
                    (root as any).$toastr.e(`Cancel active executions has been failed`, 'Error');
                });
        };

        const createdAtShort = computed(() => {
            return dayjs(props.workflow.createdAt).utc().fromNow();
        });

        const createFullDatetime = (date: Date) => {
            return dayjs(date).utc().format('DD/MM/YYYY HH:mm');
        };

        const createdAtFull = computed(() => createFullDatetime(props.workflow.createdAt));
        const user = computed(() => store.state.auth.user);
        const isUserWorkflowCreator = computed(() => user.value.id === Number(props.workflow?.createdBy?.id));
        const frameworkImagePath = computed(() => {
            const framework = ExecutionFrameworkWrapper.find(props.workflow.framework);
            if (framework) {
                return framework.imagePath;
            }
            return null;
        });

        const openWorkflow = () => {
            emit('open', props.workflow);
        };

        const configureWorkflow = () => {
            emit('configure', props.workflow);
        };

        const deleteWorkflow = () => {
            emit('delete', props.workflow);
        };

        const historyWorkflow = () => {
            emit('history', props.workflow);
        };

        const editWorkflow = () => emit('edit', props.workflow);

        const cloneWorkflow = () => emit('clone', props.workflow);

        const viewWorkflow = () => emit('view', props.workflow);

        const runWorkflow = () => emit('run', props.workflow);

        const workflowStatus = computed(() => {
            if (props.workflow.executionStatus === 'completed')
                return { class: 'text-green-700 bg-green-200', label: 'Completed' };
            if (props.workflow.executionStatus === 'draft')
                return { class: 'text-neutral-700 bg-neutral-300', label: 'Configuration' };
            if (props.workflow.executionStatus === 'failed')
                return { class: 'text-red-700 bg-red-200', label: 'Failed' };
            if (props.workflow.executionStatus === 'running')
                return { class: 'text-blue-700 bg-blue-200', label: 'Running' };
            if (props.workflow.executionStatus === 'ready')
                return { class: 'text-teal-700 bg-teal-200', label: 'Execution Pending' };
            if (props.workflow.executionStatus === 'queued')
                return { class: 'text-purple-700 bg-purple-200', label: 'Queued' };
            if (props.workflow.executionStatus === 'updating')
                return { class: 'text-secondary-700 bg-secondary-300', label: 'Update' };
            if (props.workflow.executionStatus === 'cancelled')
                return { class: 'text-orange-700 bg-orange-200', label: 'Cancelled' };
            if (props.workflow.executionStatus === 'deprecated')
                return { class: 'text-red-700 bg-red-100', label: 'Deprecated' };
            if (props.workflow.executionStatus === 'suspended')
                return { class: 'text-orange-700 bg-orange-200', label: 'Suspended' };
            return { class: 'text-danger-700 bg-danger-200', label: 'N/A' };
        });

        const filteredProvenanceAssets = computed(() => {
            const provenanceAssets: any = [];
            const filteredIds: number[] = [];

            props.workflow.inputAssets?.forEach((inputAsset: any) => {
                filteredIds.push(inputAsset.id);
                provenanceAssets.push({
                    ...inputAsset,
                    type: props.workflow.provenanceAssetIds.includes(inputAsset.id) ? 'Both' : 'Input',
                });
            });

            // add the rest of the assets in the provenance assets if they don't already exist
            props.workflow.provenanceAssets?.forEach((outputAsset: any) => {
                if (!filteredIds.includes(outputAsset.id)) {
                    filteredIds.push(outputAsset.id);
                    provenanceAssets.push({ ...outputAsset, type: 'Output' });
                }
            });
            return provenanceAssets;
        });

        return {
            isExpanded,
            createdAtFull,
            createdAtShort,
            WorkflowStatus,
            ExecutionStatus,
            hasVisualisations,
            frameworkImagePath,
            reverse,
            openWorkflow,
            toggleExpand,
            deleteWorkflow,
            historyWorkflow,
            createFullDatetime,
            configureWorkflow,
            editWorkflow,
            cloneWorkflow,
            viewWorkflow,
            formatDate,
            formatDateTime,
            cancelExecution,
            disabledCanceling,
            dayjs,
            AssetTypeId,
            workflowStatus,
            filteredProvenanceAssets,
            runWorkflow,
            isUserWorkflowCreator,
            provenanceTypeStatusClass,
            isAlertsEnabled,
        };
    },
});
