




































import { defineComponent, PropType } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import { TriggerPipelineExecutedCheck } from '.';
import { TriggerEntityType, TriggerEntityConditionType } from '../../constants';
import { TriggerPipelineExecutedCondition, Workflow } from '../../types';
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
    name: 'TriggerPipelineExecutedCondition',
    components: {
        ValidationProvider,
        TriggerPipelineExecutedCheck,
    },
    props: {
        condition: {
            type: Object as PropType<TriggerPipelineExecutedCondition>,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        workflow: {
            type: Object as PropType<Workflow>,
            required: true,
        },
        excludedPipelineIds: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
    },
    setup(props) {
        const pipelineTypes = [
            {
                id: TriggerEntityConditionType.DataCheckin,
                name: 'Data Check-in Pipeline',
            },
            {
                id: TriggerEntityConditionType.Workflow,
                name: 'Data Analytics Pipeline',
            },
        ];

        const randomUid = uuidv4();

        const handlePipelineTypeChange = () => {
            props.condition.entityName = null;
            props.condition.entityType =
                props.condition.entityTypeId === TriggerEntityConditionType.DataCheckin
                    ? TriggerEntityType.DataCheckinJob
                    : TriggerEntityType.Workflow;
            props.condition.entityId = null;
            props.condition.checks = { status: null };
        };

        return {
            pipelineTypes,
            handlePipelineTypeChange,
            randomUid,
        };
    },
});
