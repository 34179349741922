























































































































































































import * as R from 'ramda';
import { computed, defineComponent, PropType, reactive, Ref, ref, watch } from '@vue/composition-api';
import { OnClickOutside, ConfirmModal } from '@/app/components';
import { UpdateScheduleDTO } from '../dto';
import { ScheduleType, Workflow, WorkflowTrigger } from '../types';
import { WorkflowAPITriggerPlaceholder, WorkflowTriggersPlaceholder, WorklfowAPITriggerInstructions } from '.';
import { TriggerEditor, TriggersOverview } from './trigger';
import { ScheduleEditor, ViewSchedules } from './schedule';
import { ExclamationIcon } from '@vue-hero-icons/solid';
import { SelfBuildingSquareSpinner } from 'epic-spinners';
import { useWorkflowSchedules, useWorkflowTriggers } from '../composable';
import { ChevronLeftIcon } from '@vue-hero-icons/outline';

import { AccessTokenScope } from '@/app/constants/scopes';
import { AccessTokenModal } from '@/modules/user/views';
import { useFeatureFlags } from '@/app/composable';
import { WorkflowStatus } from '@/modules/apollo/constants/workflow-status.constants';

export default defineComponent({
    name: 'WorkflowTriggers',
    props: {
        error: {
            type: String,
        },
        workflow: {
            type: Object as PropType<Workflow>,
            required: true,
        },
        canEditTrigger: {
            type: Boolean,
            required: false,
        },
        frameworkVersion: {
            type: String,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        ScheduleEditor,
        ViewSchedules,
        OnClickOutside,
        ConfirmModal,
        WorkflowTriggersPlaceholder,
        TriggerEditor,
        TriggersOverview,
        ExclamationIcon,
        SelfBuildingSquareSpinner,
        WorkflowAPITriggerPlaceholder,
        WorklfowAPITriggerInstructions,
        ChevronLeftIcon,
        AccessTokenModal,
    },
    setup(props, { root, emit }) {
        const openEditor = ref(false);
        const openEventEditor = ref<boolean>(false);
        const errorInEditor = ref<string>('');
        const showTriggerEditCancelConfirmation = ref<boolean>(false);
        const changedTrigger: any = ref(null);
        const creationDifferencesState = ref<boolean>(false);
        const justCreated = ref(true);
        const workflow = computed(() => props.workflow);
        const showAccessTokenModal: Ref<boolean> = ref<boolean>(false);
        const { areAnyEnabled } = useFeatureFlags();

        const availableScopes = [AccessTokenScope.Execute, AccessTokenScope.Monitor]
            .filter((scope) => areAnyEnabled(scope.features))
            .map((scope) => {
                return {
                    ...scope,
                    checked: scope === AccessTokenScope.Execute,
                    disabled: scope === AccessTokenScope.Execute,
                };
            });

        const {
            schedules,
            loading: loadingSchedules,
            saving: savingSchedule,
            save: saveSchedule,
            changeStatus: changeScheduleStatus,
            deleteSchedule,
        } = useWorkflowSchedules();

        const {
            triggers,
            loading: loadingTriggers,
            saving: savingTrigger,
            save: saveTrigger,
            changeStatus: changeTriggerStatus,
            deleteTrigger,
            hasAccessToTriggers,
            hasInvalidInputAssetInTriggers,
        } = useWorkflowTriggers(workflow);

        const updateSchedule = (schedule: UpdateScheduleDTO) => {
            saveSchedule({ id: current.trigger.id, ...schedule });
        };

        const saving: Ref<boolean> = computed(() => savingSchedule.value || savingTrigger.value);
        const triggerEditorRef = ref<any>();

        const TriggerType = {
            Scheduled: 'scheduled',
            Event: 'event',
            API: 'api',
        };

        const current = reactive<{ trigger: any; type: string }>({
            trigger: null,
            type: '',
        });

        const differencesExist = computed(() => {
            if (current.trigger && changedTrigger.value) {
                const triggerToCompare = {
                    ...current.trigger,
                    startDate: new Date(current.trigger.startDate),
                    endDate: new Date(current.trigger.endDate),
                };
                const checkDifferences = JSON.stringify(triggerToCompare) !== JSON.stringify(changedTrigger.value);
                if (checkDifferences) {
                    emit('toggle-click-event', true);
                }
                return checkDifferences;
            }
            return false;
        });

        const close = (confirm = true) => {
            if (showAccessTokenModal.value) return;
            if (current.type === TriggerType.Scheduled) {
                if ((differencesExist.value || creationDifferencesState.value) && confirm) {
                    showTriggerEditCancelConfirmation.value = true;
                } else if (!justCreated.value) {
                    emit('toggle-click-event', false);
                    showTriggerEditCancelConfirmation.value = false;

                    if (openEditor.value && (differencesExist.value || creationDifferencesState.value)) {
                        openEditor.value = false;
                        creationDifferencesState.value = false;
                        resetCurrentTrigger();
                        emit('reset-error');
                    } else {
                        emit('cancel');
                    }
                }
            } else if (current.type === TriggerType.Event) {
                const hasChanges = triggerEditorRef.value.getHasChanges();

                if (hasChanges && confirm) showTriggerEditCancelConfirmation.value = hasChanges;
                else {
                    resetCurrentTrigger();
                    emit('toggle-click-event', false);
                    emit('reset-error');
                    emit('cancel');
                }
            } else if (!justCreated.value) {
                emit('toggle-click-event', false);
                emit('cancel');
            }
            justCreated.value = false;
        };

        const setChangedTrigger = (trigger: any) => (changedTrigger.value = trigger);

        const addTrigger = (type: string) => {
            openEditor.value = true;
            current.trigger = null;
            current.type = type;
        };
        const editTrigger = (trigger: any, type: string) => {
            if (current.trigger && current.trigger.id !== trigger.id) {
                emit('reset-error');
            }
            current.trigger = trigger;
            current.type = type;
            openEditor.value = true;
        };
        const closeEditor = () => close();

        const setCreationDifferencesState = (state: boolean) => (creationDifferencesState.value = state);

        const disabledScheduleMessage = computed(() =>
            props.disabled
                ? 'The schedule will automatically be enabled once the Analytic Pipeline is finalised.'
                : null,
        );

        const disabledTriggerMessage = computed(() =>
            props.disabled
                ? 'The trigger will automatically be enabled once the Analytic Pipeline is finalised.'
                : null,
        );

        const resetCurrentTrigger = () => {
            current.trigger = null;
            current.type = '';
        };

        const changeTriggerStatusWrapper = (trigger: WorkflowTrigger) => {
            changeTriggerStatus(trigger).catch((error) => (root as any).$toastr.e(error));
        };

        const deleteTriggerWrapper = (id: string) => {
            deleteTrigger(id)
                .then(() => (root as any).$toastr.s('Trigger has been deleted successfully', 'Success'))
                .catch((error: string) => (root as any).$toastr.e(error));
        };

        const changeScheduleStatusWrapper = (schedule: ScheduleType) => {
            changeScheduleStatus(schedule).catch((error) => (root as any).$toastr.e(error));
        };

        const deleteScheduleWrapper = (schedule: ScheduleType) => {
            if (schedule.id)
                deleteSchedule(schedule.id)
                    .then(() => (root as any).$toastr.s('Schedule has been deleted successfully', 'Success'))
                    .catch((error: string) => (root as any).$toastr.e(error));
        };

        watch(
            () => props.error,
            (error: any) => {
                errorInEditor.value = R.clone(error);
            },
            { immediate: true },
        );

        watch(
            () => saving.value,
            (s: boolean) => {
                if (!s) {
                    openEditor.value = false;
                    resetCurrentTrigger();
                    emit('reset-error');
                }
            },
        );

        return {
            openEditor,
            saveSchedule,
            changeScheduleStatus,
            editTrigger,
            closeEditor,
            addTrigger,
            updateSchedule,
            errorInEditor,
            differencesExist,
            showTriggerEditCancelConfirmation,
            setChangedTrigger,
            close,
            setCreationDifferencesState,
            disabledScheduleMessage,
            openEventEditor,
            current,
            TriggerType,
            disabledTriggerMessage,
            triggerEditorRef,
            schedules,
            triggers,
            saving,
            saveTrigger,
            changeTriggerStatusWrapper,
            deleteTriggerWrapper,
            changeScheduleStatusWrapper,
            deleteScheduleWrapper,
            hasAccessToTriggers,
            hasInvalidInputAssetInTriggers,
            loadingSchedules,
            loadingTriggers,
            AccessTokenScope,
            showAccessTokenModal,
            availableScopes,
            WorkflowStatus,
        };
    },
});
