




















































































































































import { SvgImage } from '@/app/components';
import Scrollbar from '@/app/components/Scrollbar.vue';
import { computed, defineComponent, PropType, Ref, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { ExecutionTypeWrapper } from '../../constants';
import { Execution, ExecutionLog, RunningExecution, Task, WorkflowExecution } from '../../types';
import { RunningExecutionFooter } from '../running-execution';
import TableViewOverview from './TableViewOverview.vue';
import TableViewTable from './TableViewTable.vue';

export default defineComponent({
    name: 'TableView',
    model: { prop: 'selectedTask', event: 'change' },
    props: {
        workflow: {
            type: Object,
            required: true,
        },
        selectedTask: {
            type: Object as PropType<Task>,
            required: false,
        },
        tasks: {
            type: Array as PropType<Task[]>,
            required: true,
        },
        taskMap: {
            type: Map,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        columnsPerTask: {
            type: Object,
        },
        runningExecution: {
            type: Object as PropType<RunningExecution | null>,
            default: null,
        },
        pendingExecutions: {
            type: Array,
            default: () => {
                return [];
            },
        },
        failedExecutions: {
            type: Array as PropType<WorkflowExecution[]>,
            default: () => {
                return [];
            },
        },
        blockedExecutions: {
            type: Array as PropType<WorkflowExecution[]>,
            default: () => {
                return [];
            },
        },
        otherRunningExecutions: {
            type: Array,
            default: () => {
                return [];
            },
        },
        invalidTaskIds: {
            type: Array,
            default: () => [],
        },
        isDeprecated: {
            type: Boolean,
            default: false,
        },
        latestExecution: {
            type: Object as PropType<Execution>,
            required: false,
        },
        validationErrors: {
            type: Array,
            default: () => [],
        },
        newLogMessageArrived: {
            type: Object as PropType<ExecutionLog>,
            required: false,
        },
    },
    components: { Scrollbar, TableViewOverview, TableViewTable, SvgImage, RunningExecutionFooter },
    setup(props, { emit }) {
        const isCollapsed = ref<boolean>(false);
        const overviewIconHovered = ref<boolean>(false);

        const runningExecutionTooltip = computed(() => {
            if (!R.isNil(props.runningExecution)) {
                const executionType = ExecutionTypeWrapper.find(props.runningExecution.type);
                return executionType?.message(props.runningExecution.status, props.runningExecution.task).message;
            }
            return null;
        });

        const selectedTaskObject: Ref<Task | undefined> = computed({
            get: () =>
                props.selectedTask ? props.tasks.find((task: any) => task.id === props.selectedTask?.id) : undefined,
            set: (newSelected: Task | undefined) => {
                emit('change', newSelected);
            },
        });

        const showSettings = (task: Task | null) => {
            emit('show-settings', task);
        };

        const testRun = () => {
            emit('test-run', selectedTaskObject.value);
        };

        const isValid = (task: Task) => (task ? !props.invalidTaskIds.includes(task.id) : false);

        return {
            isCollapsed,
            selectedTaskObject,
            overviewIconHovered,
            runningExecutionTooltip,
            testRun,
            isValid,
            showSettings,
        };
    },
});
